import "../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/app-tabs-header/views/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61XbYvqOBT+Pr8iCBcUjLTO6HgzzP9YWIYlbU5rdtokJHGqu+x/X5q02qYvdC4XQWw05zzPc17d/RVXpTCWR+jfJ4QqzuyZoDiKfrw9IXQGnp8tQYe9utbPjBtV0BtBWQHugBY8F5hbKA1BKQgLuj6uv8aMa0gtl4IgLav6OKeKoJM3Jb9AZ4WsCEoLruoTJXl9H8MXCGsIohcr63NcQfLJLaZKYQ25M8g0zd+e/nva3eH/yailmBtcccFkZd5XGS0MrD4cL0UZ4yLH2vNxGDq3Y/ejATtHw4NsGEzw/ftiLM9uOJXCgrAEGUVTwAnYCkC8PQAQFKH4qB7WzVlz8UlQNMamlAzeV1Sp1QcKsFpNheFeXRc0FO2ezbb14556JvczFIeRmuDZYTEZxjZnfA51EXhSigsB7H1l9aWNzowSz+4HCU0/cy0vghH0RfUaY5plXADmqRTYQCoFo/q2eeuksMd3RzMhOkIl1TkXU5nxMu8/kZqBxqkspJ7z3tTTDNHDeHwmAhEaHqumNj00FNTyLwgC2Aji6dcH+BhwPzpIravnpgeUXOCGZXs09D4t2P2LR9g68g34DyrLS2iptjPyOIOBZwtXO+rzYkBjAwWkliAhhROqCaymjF8MQS+e6LioYw1KSDzoUQcUSDufekdfLzSt/fTqZYm6SvOyzzORde5RVsepd8sf4ngz5r4t15luuo/GkX+v0g/+Uq1azkV+vxZo1mURuVesrn0+IfWOk1ev+zCFh50tLLFBarpp1iRGJoXFVTsto2gmN0t6bZ3Hp8hfTy/a1AnLIKOXwvYhk4Iai2WG7U3BmP59HU8hRQfGZf+jTqEouDLcjFdvdeYWsJthdSZXmqpvV5VTxPB/ILjgzq8mCAs51yhQQGLS4VyER6tmgeE5m/0QBMYCab2Inds/J12OTK+lA2DQF9uvOo5pb6U7TgzER5wWLCZ9dWtxO9rSX9K2Z9F1mY8wG6YNOwVHrCa+ToKNsm3ujzZOEyOLi3Wnj2pCyErVfEqktbJsHsKeEGg7rKSl4Qwquukq48MPBOt3W8LNehiXLfIybh46JkRIuyZQKnvbdJPjZaKDj8c5GQyhggugGuf1uARh1/vXiEG+XTic0GG/Ox1/bJHOE7reHw5b9HiLdlG0cYKPjqde3vxOiGFPQ8fD7jW+g3x52aLmbQZkOpqG7R+bqcoMB06btt8pgOVJkY4tnh1L7DG4c13ndrxoOk5OP7fyFJBZgvBpeq3qIuhsBsBcq/VsCM0s6KY5NCWyWk1XuKvqOOrL3W4ADaT42T92u0ETqOaHwWrYnk7tZDO9r5nmo1hDeD3sSwBJRVNub20b+xV4XndgObyvanmaJe5u2WWC+yeaSV0S/7GgFv5Y44O6zlhz6jbmukovMv3Tm/4fxVC5TbsQAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var dropIndicator = '_1wmnstie';
export var headerLeft = '_1wmnsti1';
export var labelIcon = '_1wmnstia _1wmnsti9';
export var pinSeparator = '_1wmnsti3';
export var root = '_1wmnsti0';
export var spacer = '_1wmnstid';
export var splitViewLabel = '_1wmnsti7';
export var splitViewLabelText = '_1wmnsti8';
export var splitViewSeparator = '_1wmnsti4';
export var tab = '_1wmnsti6';
export var tabCloseButton = '_1wmnstic _1wmnsti9';
export var tabCloseButtonWrapper = '_1wmnstib';
export var tabIcon = '_1wmnsti9';
export var tabWrapper = '_1wmnsti5';
export var tabs = '_1wmnsti2';